.upload-page-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100vw;
  overflow-y: hidden;

  .background-image{
    background: url("../../assets/midea/background/detail-background.png") no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    top:0;
    left: 0;
    width: 100vw;
    height:0;
    padding-bottom: 300%;
    overflow-y: scroll;
  }

  .content{
    position: relative;
    color: white;
    width: 100%;

    .header{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 22px;

      .title{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
      }

      .skipText{
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        margin-right: 22px;
      }
    }

    .uploadContainer{
      position: relative;
      height: 293px;
      width: 100%;

      .uploadFrame{
        height: 100%;
        position: relative;
      }

      .cameraIcon{
        height: 34px;
        position: absolute;
        top: 48%;
        left: 50%;
        margin-left: -17px;
        margin-top: -17px;
      }

      .uploadBackground{
        height: 276px;
        width: 276px;
        background: #C4C4C4;
        opacity: 0.5;

        position: absolute;
        top: 3%;
        left: 50%;
        margin-left: -138px;
      }

      .uploadImg{
          height: 276px;
          width: 276px;

          position: absolute;
          top: 3%;
          left: 50%;
          margin-left: -138px;
      }

      .uploadText{
        position: absolute;
        height: 20px;
        width: 100px;
        top: 58%;
        left: 50%;
        margin-top: -10px;
        margin-left: -50px;
      }
    }

    .storyContainer{
      margin-top: 3vh;

      .textFrameContainer{
        margin-top: 20px;
        position: relative;

        .textFrame{
          height: 160px;
          width: 310px;
        }

        .textArea{
          background: none;
          color: #FFFFFF;
          opacity: 1;
          border: none;
          outline: none;
          width: 270px;
          height: 116px;

          position: absolute;
          left: 50%;
          margin-left: -135px;
          top: 50%;
          margin-top: -58px;

          font-style: normal;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }

    .activityContainer{
      margin-top: 3vh;

      .textFrameContainer{
        margin-top: 20px;
        position: relative;

        .textFrame{
          height: 160px;
          width: 310px;
        }

        .text{
          width: 270px;
          height: 116px;

          text-align: left;
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 20px;

          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -135px;
          margin-top: -58px;
        }
      }
    }

    .prizeContainer{
      margin-top: 3vh;

      .slideContainer{
        margin-top: 25px;
        height: 150px;
        width: 100%;
      }
    }

    .dutyText{
      margin-top: 30px;
      margin-left: 5%;
      margin-bottom: 20px;
      width: 90%;
      font-style: normal;
      font-weight: 400;
      font-size: 9px;
      color: rgba(255, 255, 255, 0.2);
    }

    .buttonContainer {
      margin:auto
    }
  }
}