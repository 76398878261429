@font-face {
  font-family: 'kroeger';
  src: url('../../assets/midea/KR-08-54.TTF');
}

.detail-page {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("../../assets/midea/background/detail-background.png") no-repeat center;
  background-size: 100% 100%;

  .detail-page-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .nft-swiper-title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 18px;
      color: #FFFFFF;
      margin-top: 69px;
    }

    .nft-title-background {
      background: url("../../assets/midea/title-back.png") no-repeat center;
      background-size: 100% 100%;
      width: 221px;
      height: 17px;
      margin-top:-6px;
      margin-bottom: 14px;
    }

    .nft-id {
      font-family: 'kroeger';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #FFFFFF;
    }

    .nft-image-container {
      height: 319px;
      width: 297px;
      background: url("../../assets/midea/nft-border.png") no-repeat center;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items:center;
      margin-top: 20px;

      .nft-image {
        width: 264px;
        height:264px;
        margin-top: 30px;
      }
    }

    .assist-container {
      width: 152px;
      height: 40px;
      background: rgba(36, 59, 142, 0.5);
      border: 0.5px solid #6978A0;
      border-radius: 40px;
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .eye-icon {
        background: url("../../assets/midea/eye-icon.png") no-repeat center;
        background-size: 100% 100%;
        width:20px;
        height:20px;
        margin-left:17px;
        margin-right:4px;
        margin-bottom: 3px;
      }

      .assist-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;
      }

      .assist-num {
        min-width: 50px;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;
        text-align: center;
      }
    }

    .detail-page-info-container {
      width: 343.5px;
      height:170px;
      backdrop-filter: blur(5px);
      border: 0.5px solid #87CDFF80;
      box-shadow: inset 0px 0px 14px #8FAFFF66;
      margin-top:25px;
      display:flex;
      flex-direction: column;
      align-items: center;
      z-index: 10;

      .info-container-title {
        margin-top:10px;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;
      }

      .info-content-container {
        width: 319px;
        height:17px;
        margin-top:12px;
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .info-title {
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: #FFFFFF;
        }

        .info-content {
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: #FFFFFF;
        }
      }
    }

    .photo-container {
      position: relative;
      background: url("../../assets/midea/photoFrame.png") no-repeat center;
      background-size: 100% 100%;
      width: 344px;
      height:352px;
      margin-top: 25px;

      .photo-image {
        position: absolute;
        top: 34px;
        left: 38px;
        width: 249px;
        height:249px;
      }
    }

    .button-groups {
      position: relative;
      width: 85%;
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content:space-between;
      padding-left: 10px;
      margin-top: 20px;
      height: 60px;
      margin-bottom: 65px;

      .buttonContainer {
        width: 132px;
  
        .underBorder {
          width: 132px;
        }
      }

      .go-to-coupon {
        position: absolute;
        height:40px;
        width: 140px;
        right: 0;
        top:0;
        z-index: 100;
      }
    }

  }
  .bottom-text {
    position: absolute;
    bottom: 20px;
    width: 96%;
    font-weight: 400;
    font-size: 9px;
    line-height: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.2);
  }
}
