.activityPageContainer{
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  .background {
    background: url("../../assets/midea/background/detail-background.png") no-repeat center;
    background-size: 100% 100%;
    position: relative;
    width: 100vw;
    height: 0;
    padding-bottom: 233%;
  }

  .content {
    position: absolute;
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .mallIcon {
      width: 129px;
      margin-bottom: 20px;
      margin-top: 54px;
    }

    .mallFrameContainer {
      margin-top: 20px;
      position: relative;

      .mallFrame {
        height: 224px;
        width: 304px;
      }

      .mallFrame2{
        height: 260px;
        width: 304px;
      }

      .mallContent{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .jdMallMideaIcon{
          width: 203px;
          height: 86px;
          margin-top: 20px;
          margin-bottom: 15px;
        }

        .mediaForHomeDesc{
          height: 71px;
          width: 266px;
          margin-top: 35px;
          margin-bottom: 20px;
        }

        .mediaForHomeLink{
          width: 265px;
          height: 118px;
        }

        .evaluationDesc{
            width:212px;
          height: 44px;
          margin-top: 25px;
          margin-bottom: 16px;
        }

        .mideaText{
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 7px;
        }

        .evaluationLink{
          width: 265px;
          height: 118px;
        }

        .tbMallMideaIcon{
          width: 216px;
          height: 83px;
          margin-top: 20px;
          margin-bottom: 15px;
        }

        .picLink{
          width: 265px;
          height: 118px;
          cursor: pointer;
        }

      }
    }
  }

  .dutyText{
    position: absolute;
    bottom: 20px;
    width: 336px;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    color: rgba(255, 255, 255, 0.2);
  }
}