.login-page {
  position: relative;
  width: 100vw;
  min-height: 100vh;

  .policy-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    background:#FFFFFF;

    .policy-close-button {
      position: absolute;
      top:26px;
      left:17px;
      background: url("../../assets/midea/policy-close-button.png") no-repeat center;
      background-size: 100% 100%;
      width: 13px;
      height: 13px;
    }

    .policy-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      letter-spacing: -0.3px;
      color: #101010;
      margin-top: 20px;
      width: 100%;
      padding-bottom: 18px;
      border-bottom: 1px solid #F4F4F4;;
      margin-bottom:10px;
    }

    .policy-content {
      overflow: scroll;
      white-space: pre-wrap;
      width: 92%;
      height:82vh;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: justify;
      color: #101010;
      padding-bottom: 20px;
    }
  }

  .back-icon {
    position: absolute;
    top: 30px;
    left:23px;
    background: url("../../assets/midea/back-icon-black.png") no-repeat center;
    background-size: 100% 100%;
    width: 8px;
    height:13px;
    z-index: 99;
  }

  .login {
    position: relative;
    height: 100vh;
    padding: 0 28px;
    background-size: cover;
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .title-container{
      width: 118px;
      height: 85px;
      padding-top: 80px;
      padding-bottom: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
  
      img{
        height: 68px;
        width: 123px;
      }
    }

    .agreement-container{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin-top: -8px;
  
      .agreement-label{
        display: flex;
        align-items: center;
        margin-left: 8px;
      }
    }
  
    .input-container{
      width: 335px;
      height: 40px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 23px;
      font-size: 14px;
      line-height: 20px;
      border-bottom: 0.5px solid rgba(199, 199, 199, 0.4);
      color: #101010;
      ::-webkit-input-placeholder{
        color: #C7C7C7;
      }
  
      button{
        font-size: 14px;
        border:none;
      }
  
      input{
        border:none;
        padding: 0 0 0 0;
        font-size: 14px;
        outline: none;
        padding-left: 3px;
      }
  
      .phone{
        width: 100%;
        height: 100%;
      }
  
      .input-wrapper .code{
        width: 180px;
        height:100%;
        margin-right: 18px;
      }
  
      .code-button{
        background-color:white;
        width: 100px;
        border:none;
        padding: 0;
        text-align: right;
      }
  
      .code-button:disabled{
        color: white;
      }
  
      .full-length-button{
        width: 276px;
        height: 46px;
        background: #4299EF;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #FFFFFF;
      }
    }

    .button-container {
      display:flex;
      justify-content: center;
      align-items:center;
      margin-top: 25px;
    }
  }
} 
