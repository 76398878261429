.desc-container{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  z-index: 3;
  
  .desc-content{
    max-width: 231px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
  }
}