#about-container{
  position: relative;
  width: 100vw;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  .background {
    background: url("../../assets/midea/background/short-background.png") no-repeat center;
    background-size: 100% 100%;
    position: relative;
    width: 100vw;
    height:0;
    padding-bottom: 195%;
  }

  .back-icon {
    position: absolute;
    top: 30px;
    left:23px;
    background: url("../../assets/midea/back-icon-white.png") no-repeat center;
    background-size: 100% 100%;
    width: 8px;
    height:13px;
    z-index: 99;
  }

  .about-page-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #about-content{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    width: 318px;
    margin-top: 80px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;  
    text-align: justify;
    z-index: 20;
    color: #FFFFFF;

    #about-title{
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 30px;
      text-align: justify;
      color: #FFFFFF;
    }

    .lineBetween{
      margin: 35px 0px 35px 0px;
    }
  }
}