.buttonContainer{
  width: 202px;
  height: 40px;
  background: none;
  color: white;
  position: relative;
  cursor: pointer;

  border:1px dashed #787878;

  .underBorder{
    position: absolute;
    width: 202px;
    height: 40px;
    background: #46A1E0;
    top:-10px;
    left: -9px;

    font-family: 'PingFang HK';
    font-style: normal;
    font-weight: 600;
    z-index: 100;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
