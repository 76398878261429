.menu-icon {
  position: absolute;
  top:25px;
  left:16px;
  height: 17px;
  width: 18px;
  display: flex;
  justify-content:center;
  align-items:center;
  z-index: 99;
}

.menu-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.menu-buttons-container {
  position: absolute;
  top: 50px;
  left: 17px;
  width: 84px;
  height: 103px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  z-index: 101;

  .nav-button {
    position: relative;
    width: 100%;
    height: 34px;
    border-bottom: 0.5px solid #97979733;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
}

.buttons-container-4 {
  height:136px
}
