.fullscreen-loading{
  overflow-y: hidden;
  position: absolute;
  left:0;
  top:0;
  z-index: 7;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color:white;
  font-size: 16px;

  .loading-container{
  }

  .wave-container{
    width: 306px !important;
    height: 40px;
    opacity: 0.4;
    margin-top: -10px;
  }

  .text-container{
    width: 130px !important;
    height: 16px;
    font-size: 16px;
  }

}