.mideaCongratsContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100vw;
  min-height: 100vh;

  .backgroundImage{
    position: relative;
    width: 100vw;
    min-height: 100vh;
  }

  .content{
    position: absolute;
    top:0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    z-index: 2;
    width: 100%;

    .nft-swiper-title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 18px;
      color: #FFFFFF;
      margin-top: 58px;
    }

    .nft-title-background {
      background: url("../../assets/midea/title-back.png") no-repeat center;
      background-size: 100% 100%;
      width: 221px;
      height: 17px;
      margin-top:-6px;
      margin-bottom: 14px;
    }

    .nft-id {
      font-family: 'kroeger';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #FFFFFF;
      margin-bottom: 14px;
    }

    .nftContainer{
      position: relative;
      margin-bottom: 30px;

      .nftBorder{
        width: 297px;
      }

      .nftImg{
        width: 264px;
        position: absolute;
        left: 17px;
        top: 31px;
      }
    }

    .buttonGroupContainer{
      position: relative;
      display: flex;
      flex-direction: row;
      width: 80%;
      justify-content: space-between;
      padding-left: 10px;
      margin-top: 60px;

      .buttonContainer{
        width: 132px;
        .underBorder{
          width: 132px;
        }
      }

      .go-to-coupon {
        position: absolute;
        height:50px;
        width: 140px;
        right: 0;
        top:-10px;
        z-index: 100;
      }
    }
  }

  .dutyText{
    position: absolute;
    left: 50%;
    margin-left: -168px;

    bottom: 20px;

    width: 336px;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    color: rgba(255, 255, 255, 0.2);
  }
}