.uploadedContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100vw;
  min-height: 100vh;

  .backgroundImage{
    position: relative;
    width: 100vw;
    min-height: 100vh;
  }

  .content{
    position: absolute;
    top:0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    z-index: 2;
    width: 100%;

    .title{
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: white;
      margin-top: 20px;
    }

    .photoContainer{
      position: relative;
      width: 338px;
      height: 346px;
      margin-bottom: 80px;

      .photo{
        position: absolute;
        top: 97px;
        left: 37px;
        width: 245px;
        height: 245px;
      }

      .photoFrame{
        width: 338px;
        height: 346px;
        margin-top: 64px;
      }
    }
  }

  .dutyText{
    position: absolute;
    left: 50%;
    margin-left: -168px;

    bottom: 20px;

    width: 336px;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    color: rgba(255, 255, 255, 0.2);
  }
}