@font-face {
  font-family: 'kroeger';
  src: url('../../assets/midea/KR-08-54.TTF');
}

.home-page {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: url("../../assets/midea/background/detail-background.png") no-repeat center;
  background-size: 100% 100%;
  padding-bottom: 85px;

  .home-page-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .nft-swiper-title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 18px;
      color: #FFFFFF;
      margin-top: 69px;
    }
  
    .nft-title-background {
      background: url("../../assets/midea/title-back.png") no-repeat center;
      background-size: 100% 100%;
      width: 221px;
      height: 17px;
      margin-top:-6px;
      margin-bottom: 14px;
    }
  
    .nft-id {
      font-family: 'kroeger';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #FFFFFF;
    }

    .PhotoDescContainer {
      margin-top:18px;
    }

    .buttonContainer {
      margin-top:40px;
    }

    .click-icon {
      position: absolute;
      top:628px;
      left:35px;
      background: url("../../assets/midea/arrow-icon.png") no-repeat center;
      background-size: 100% 100%;
      width: 16px;
      height: 16px;
    }

    .three-dots {
      position: relative;
      background: url("../../assets/midea/3dots.png") no-repeat center;
      background-size: 100% 100%;
      width: 4px;
      height: 46px;
      margin-top: 30px;
    }

    .ornament1 {
      position: absolute;
      top:625px;
      right:-140px;
      background: url("../../assets/midea/ornament1.png") no-repeat center;
      background-size: 100% 100%;
      height:181px;
      width: 214px;
    }

    .ornament2 {
      position: absolute;
      top:1020px;
      left:-115px;
      background: url("../../assets/midea/ornament2.png") no-repeat center;
      background-size: 100% 100%;
      height:137.5px;
      width: 173px;
    }

    .home-title-1 {
      background: url("../../assets/midea/home-title2.png") no-repeat center;
      background-size: 100% 100%;
      width: 240px;
      height: 46px;
      margin-top: 20px;
    }

    .info-container-border {
      background: url("../../assets/midea/info-container-border.png") no-repeat center;
      background-size: 100% 100%;
      width: 307px;
      height:153px;
      margin-top: 17.5px;
      display: flex;
      align-items: center;
      justify-content: center;

      .info-text {
        width: 270px;
        height: 116px;
        overflow:scroll;
        font-weight: 400;
        font-size: 11px;
        line-height: 20px;
        text-align: justify;
        color: #FFFFFF;
      }
    }

    .info-container-border-large {
      background: url("../../assets/midea/info-container-border-large.png") no-repeat center;
      background-size: 100% 100%;
      width: 307px;
      height:211px;
      margin-top: 17.5px;
      display: flex;
      align-items: center;
      justify-content: center;

      .info-text {
        width: 270px;
        height: 180px;
        overflow:scroll;
        font-weight: 400;
        font-size: 11px;
        line-height: 20px;
        text-align: justify;
        color: #FFFFFF;
      }
    }

    .home-title-2 {
      background: url("../../assets/midea/home-title1.png") no-repeat center;
      background-size: 100% 100%;
      width: 161px;
      height: 46px;
      margin-top: 65px;
    }
  }

  .bottom-text {
    position: absolute;
    bottom: 18px;
    width: 96%;
    font-weight: 400;
    font-size: 9px;
    line-height: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.2);
  }
}
