.cropper .cropper-content {
    font-size: 14px;
    line-height: 24px;
    text-align: justify;
    color: #101010;
}

.cropper .cropper-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.3px;
    color: white;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.cropper .confirm {
    margin-left: 5vw;
    width: 90vw;
    height: 50px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 75px;
    border: none;
}

.cropper .back {
   position: absolute;
    left: 10px;
    top: 12px;
}