.midea-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 9999;

  .loading-background {
    background: url("../../assets/midea/background/short-background.png") no-repeat center;
    background-size: 100% 100%;
    position: relative;
    width: 100vw;
    height:0;
    padding-bottom: 195%;
  }

  .loading-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .loading-icon {
      background: url("../../assets/midea/loading.gif") no-repeat center;
      background-size: 100% 100%;
      height:81px;
      width: 81px;
      margin-top: 240px;
    }

    .loading-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.3px;
      color: #FFFFFF;
    }

    .bottom-text {
      position: absolute;
      bottom: 20px;
      width: 96%;
      font-weight: 400;
      font-size: 9px;
      line-height: 16px;
      text-align: center;
      color: rgba(255, 255, 255, 0.2);
    }
  }
}