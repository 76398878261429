.PhotoDescContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  position: relative;
  height: 60px;

  .leftIconContainer{
    width: 20px;
    margin-right: 21px;
    margin-bottom: 30px;

    .iconImage{
      width: 14px;
    }
  }

  .RightIconContainer{
    width: 20px;
    margin-left: 21px;
    margin-top: 30px;

    .iconImage{
      width: 14px;
    }
  }

  .textContainer{
    max-width: 231px;
    max-height: 60px;
    word-wrap:break-word;
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
  }

  .textContainer2 {
    max-width: 231px;
    max-height: 60px;
    white-space: pre-wrap;
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
  }
}