@font-face {
  font-family: 'kroeger';
  src: url('../../assets/midea/KR-08-54.TTF');
}

.poster-page {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  .back-icon {
    position: absolute;
    top: 32px;
    left:23px;
    background: url("../../assets/midea/back-icon-white.png") no-repeat center;
    background-size: 100% 100%;
    width: 8px;
    height:13px;
    z-index: 99;
  }

  .stageDiv {
    position: relative;
    width: 100vw;
    display: flex;
    flex-direction:column;
    align-items: center;

    .background {
      background: url("../../assets/midea/background/short-background.png") no-repeat center;
      background-size: 100% 100%;
      position: relative;
      width: 100vw;
      height:0;
      padding-bottom: 190%;
    }

    .poster-page-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      display: flex;
      flex-direction: column;

      .nft-swiper-title {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 18px;
        color: #FFFFFF;
        margin: 25px auto 0 auto;
      }

      .nft-title-background {
        background: url("../../assets/midea/title-back.png") no-repeat center;
        background-size: 100% 100%;
        width: 221px;
        height: 17px;
        margin:-6px auto 14px auto;
      }

      .nft-id {
        font-family: 'kroeger';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #FFFFFF;
        margin: 0 auto 0 auto;
      }

      .poster-nft-image-container {
        height: 319px;
        width: 297px;
        background: url("../../assets/midea/nft-border.png") no-repeat center;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        margin: 10px auto 0 auto;
  
        .poster-nft-image {
          position: relative;
          width: 264px;
          height:264px;
          margin: 30px auto 0 auto;
        }
      }

      .poster-page-info-container {
        width: 343.5px;
        height:170px;
        backdrop-filter: blur(5px);
        border: 0.5px solid #87CDFF80;
        box-shadow: inset 0px 0px 14px #8FAFFF66;
        margin: 15px auto 0 auto;
        display:flex;
        flex-direction: column;
        align-items: center;
        z-index: 10;
  
        .info-container-title {
          margin: 10px auto 0 auto;
          font-weight: 500;
          font-size: 18px;
          line-height: 25px;
          text-align: center;
          color: #FFFFFF;
        }
  
        .info-content-container {
          width: 319px;
          height:17px;
          margin: 12px auto 0 auto;
          display:flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
  
          .info-title {
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            color: #FFFFFF;
            width: 120px;
            text-align:start;
          }
  
          .info-content {
            width: 200px;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            color: #FFFFFF;
            text-align:end;
          }
        }
      }

      .bottom-container {
        position: relative;
        height:62px;
        width: 343.5px;
        margin: 18px auto 0 auto;
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          flex-direction: row;

          .qrcode-image {
            width: 61px;
            height:61px;
          }

          .qrcode-text {
            display:flex;
            flex-direction: column;
            font-weight: 400;
            width: 130px;
            font-size: 12px;
            line-height: 20px;
            display: flex;
            align-items: start;
            color: #FFFFFF;
            margin-top:23px;
            margin-left: 10px;
          }
        }

        .right {
          margin-top: 25px;
          background: url("../../assets/midea/logo-group.png") no-repeat center;
          background-size: 100% 100%;
          width: 121px;
          height: 26px;
        }
      }
    }
  }
}

@media screen and (min-height: 720px) {
  .poster-page {
    .stageDiv {
      .background {
        padding-bottom: 179%;
      }
      .poster-page-content {
        .poster-page-info-container {
          margin: 35px auto 0 auto;
        }
        .bottom-container {
          margin: 28px auto 0 auto;
        }
      }
    }
  }
}