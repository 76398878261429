.upload-process-container{
  width: 100%;
  height: 60px;
  color: white;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 4vh;
  margin-top: 2vh;

  .process-bar{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .circle {
    width: 18px;
    height: 18px;

    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'DIN Alternate';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;

    box-shadow:0 0 0 4px rgba(255,255,255,0.2);
  }

  .line{
    margin: 0px 13px;
    background: linear-gradient(90deg, rgba(255,255,255,0.42) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    width: 88px;
    height: 2px;
  }

  .dashedLine{
    width: 88px;
    height: 2px;
    border-top: 1px dashed rgba(42, 145, 249, 1);
    margin-left: 13px;
    margin-right: 13px;
    margin-top: 3px;
  }

  .hook{
    width: 10px;
    height: 7px;
  }

  .desc-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 375px;
  }

  .desc{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-top: 15px;
    color: rgba(255, 255, 255, 0.7);
  }

  .descHighLight{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-top: 15px;
  }
}