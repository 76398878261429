.nft-swiper {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .nft-card {
    height: 319px;
    width: 297px;
    background: url("../../assets/midea/nft-border.png") no-repeat center;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
  }

  .nft-image {
    position: relative;
    width: 264px;
    height:264px;
    margin-top: 10%;
  }

  .nft-subTitle{
    position: absolute;
    bottom: -20px;
    left: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
  }
}