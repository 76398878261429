.mediaClaimPageContainer{
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .loadingContent{
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loadingImage{
      width: 81px;
      position: relative;
    }

    .loadingBackground{
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
    }

    .loadingText{
      color: white;
      position: relative;
    }

    .loadingDutyText{
      position: absolute;
      font-style: normal;
      font-weight: 400;
      font-size: 9px;
      line-height: 16px;
      text-align: center;
      color: rgba(255, 255, 255, 0.2);

      bottom: 20px;
      width: 90%;
    }
  }



  .background {
    background: url("../../assets/midea/background/short-background.png") no-repeat center;
    background-size: 100% 100%;
    position: relative;
    width: 100vw;
    height:0;
    padding-bottom: 216.5%;
  }

  .content{
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .title{
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: white;
      margin-top: 24px;
      margin-bottom: 33px;
    }

    .nft-swiper-title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 18px;
      color: #FFFFFF;
      margin-top: 50px;
    }

    .nft-title-background {
      background: url("../../assets/midea/title-back.png") no-repeat center;
      background-size: 100% 100%;
      width: 221px;
      height: 17px;
      margin-top:-6px;
      margin-bottom: 14px;
    }

    .nft-id {
      font-family: 'kroeger';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #FFFFFF;
      margin-bottom: 14px;
    }

    .leftCount{
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      color: white;
      margin-bottom: 40px;
    }

    .dutyText{
      position: absolute;
      font-style: normal;
      font-weight: 400;
      font-size: 9px;
      text-align: center;
      bottom: 20px;
      width:90%;
      margin-top: 20px;

      color: rgba(255, 255, 255, 0.2);
    }
  }
}

@media screen and (min-height: 740px) {
  .mediaClaimPageContainer{
    .content{
      .nft-swiper-title{
        margin-top: 50px
      }
    }
  }
}